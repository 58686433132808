import { IcmsTax } from 'icmsTaxes/models/icmsTaxes';
import { TableBodyColumn, TableBodyRow } from 'ui/components/table';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';

import { Tooltip } from 'ui/components/tooltip';

type Props = {
  icmsTax: IcmsTax;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

const ICMS_VALIDITY_DATE_FORMAT_CONFIG = {
  locale: 'pt-BR',
};

const formatTariffGroupValidityDate = (date: Date, config: { locale: string }) => {
  const splitDate = date.toString().split('-');

  const dateFormatted = `${splitDate[0]}-${splitDate[1]}-${splitDate[2].substring(
    0,
    2
  )} 00:00:00`;

  return new Intl.DateTimeFormat(config.locale).format(new Date(dateFormatted));
};

export default function UsersTableItem({
  icmsTax: {
    id,
    icmsCountryState,
    icmsConsumerUnitConsumptionClass,
    icmsValidityStartDate,
    icmsValidityEndDate,
    icmsRangesFee,
  },
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  const handleClickEditButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  return (
    <TableBodyRow dataTestId={`icms-taxes-row-id-${id}`}>
      <TableBodyColumn className="font-bold">
        {icmsConsumerUnitConsumptionClass}
      </TableBodyColumn>
      <TableBodyColumn className="text-center">{icmsCountryState}</TableBodyColumn>
      <TableBodyColumn>{`${formatTariffGroupValidityDate(
        icmsValidityStartDate,
        ICMS_VALIDITY_DATE_FORMAT_CONFIG
      )} - ${formatTariffGroupValidityDate(
        icmsValidityEndDate,
        ICMS_VALIDITY_DATE_FORMAT_CONFIG
      )}`}</TableBodyColumn>
      {icmsRangesFee.map((icmsRange, index) => (
        <TableBodyColumn key={index} className="font-bold">
          <Tooltip
            label={
              <>
                {icmsRange.consumptionKwhStartRange} -{' '}
                {icmsRange.consumptionKwhEndRange}
                Kwh
              </>
            }
            placement="top-start"
            offset={{ crossAxis: -10 }}
          >
            {`${icmsRange.consumptionRangePercentage}%`}
          </Tooltip>
        </TableBodyColumn>
      ))}

      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} />
        <RemoveButton onClick={handleClickRemoveButton} />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
