import NexLogo from 'assets/logo/NexLogo';

import { EconomyReport } from 'economyReport/model/economyReport';
import { GenerationUnitConsumptionGroupTypes } from 'generationUnits/models/generationUnit';
import { useMemo } from 'react';

export type Props = {
  economyReport: EconomyReport;
};

export default function SecondPageEconomyReport({ economyReport }: Props) {
  const HAS_CONSUMPTION_GROUP_TYPE_B = useMemo(
    () =>
      economyReport?.generationUnit?.generationUnitConsumptionGroupType ===
      GenerationUnitConsumptionGroupTypes.B,
    [economyReport?.generationUnit?.generationUnitConsumptionGroupType]
  );

  const FAQ_INFO = [
    {
      id: 1,
      title: 'COMO FUNCIONA O PAGAMENTO DO MODELO NEX?',
      description:
        'Mensalmente, você receberá um boleto único já com o valor do aluguel da usina mais o valor da Gestão NEX.',
    },
    {
      id: 2,
      title: 'COMO FICA A MINHA GESTÃO DE DOCUMENTOS FISCAIS?',
      description: HAS_CONSUMPTION_GROUP_TYPE_B
        ? 'Pelo fato do nosso boleto unificar o valor do Aluguel da Usina e da Gestão NEX, seu valor representará a soma dos custos da Nota Fiscal (NEX) e do Recibo de Locação (Aluguel da Usina)'
        : 'Pelo fato do nosso boleto unificar os valores do Aluguel da Usina, Gestão NEX e Custo Operativo da Cooperativa, seu valor representará a soma dos custos da Nota Fiscal (NEX), do Recibo de Locação (Aluguel da Usina) e Nota de Débito (Cooperativa).',
    },
    {
      id: 3,
      title: 'COMO PEDIR SEGUNDA VIA DO BOLETO NEX?',
      description:
        'Basta você entrar em contato com nossa equipe através do email <br/> faturamento@nexenergy.com.br',
    },
    {
      id: 4,
      title: 'O QUE SÃO CRÉDITOS DE ENERGIA?',
      description:
        'Toda a energia excedente gerada pela usina alugada é injetada na rede elétrica da concessionária e transformada em créditos. Esses créditos serão abatidos da sua conta de luz, gerando economia para seu estabelecimento. <br/> <br/>Os créditos recebidos são todos aqueles que a sua unidade consumidora adquiriu referente ao período da geração. Já os créditos utilizados são relacionados ao consumo de energia efetivo da sua unidade consumidora durante o mês. ',
    },
    {
      id: 5,
      title: 'COMO VOCÊ CONTRIBUI PARA UM MUNDO MAIS SUSTENTÁVEL?',
      description:
        'Ao fazer parte da NEX, você possibilita que a geração de energia renovável e de baixo impacto ambiental seja viável. Garantindo, assim, a construção de um futuro mais sustentável e inteligente. ',
    },
    {
      id: 6,
      title: 'COMO GERAMOS ECONOMIA PARA VOCÊ?',
      description:
        'Com a NEX, você utiliza energia de fontes renováveis e reduz o custo de energia em relação ao valor pago anteriormente à concessionaria, trazendo economia para unidade consumidora ao longo do contrato.',
    },
    {
      id: 7,
      title: 'VOU CONTINUAR RECEBENDO A FATURA DA MINHA CONCESSIONÁRIA?',
      description:
        'Ao entrar na NEX, você vai continuar recebendo a fatura da sua concessionária, que deverá ser paga normalmente até o dia do vencimento. Vale lembrar, que na maioria dos meses a soma do valor da sua fatura e do boleto NEX será inferior ao custo com energia que você teria se não contasse com os créditos  de energia das nossas usinas parceiras.',
    },
    {
      id: 8,
      title: 'COMO FUNCIONAM AS BANDEIRAS TARIFÁRIAS COM A NEX?',
      description:
        'Bandeiras tarifárias são tarifas adicionais da energia. Elas estão relacionadas a necessidade de fontes não renováveis para o atendimento energético do país. <br/> <br/>Os créditos recebidos das usinas da NEX são integralmente produzidos por energia limpa e renovável, por isso você não paga esse adicional.',
    },
    {
      id: 9,
      title: 'O QUE É A ECONOMIA FUTURA?',
      description:
        'Economia futura acontece quando a Usina gerar mais créditos que a sua unidade consumidora consumiu. Esses créditos restantes representam essa economia futura garantida que você poderá utilizar em até 60 meses.',
    },
  ];

  const addSpaceBetweenParagraphs = (text: string) =>
    text.split(/<br\/>/g).map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });

  return (
    <div
      id="economy-report-second-page"
      className="flex flex-col w-full items-center justify-start h-screen bg-white p-7 gap-2 font-rubik relative"
      style={{
        width: '210mm',
        height: '297mm',
      }}
    >
      <header className="w-full flex items-center justify-start pb-6">
        <h1
          className="font-bold"
          style={{
            fontSize: '2rem',
            lineHeight: '2rem',
          }}
        >
          Perguntas Frequentes
        </h1>
      </header>
      <section className="flex gap-6">
        <div className="flex flex-col items-start justify-start w-full gap-6">
          {FAQ_INFO.slice(0, 5).map((faq) => (
            <div
              key={faq.id}
              className="flex flex-col w-full items-start justify-start gap-1 bg-gray-dark100 p-4"
            >
              <h2
                className="font-medium"
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.15rem',
                }}
              >
                {faq.title}
              </h2>
              <p
                className="text-sm"
                style={{
                  lineHeight: '.95rem',
                }}
              >
                {addSpaceBetweenParagraphs(faq.description)}
              </p>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-6">
          {FAQ_INFO.slice(5, 10).map((faq) => (
            <div
              key={faq.id}
              className="flex flex-col w-full items-start justify-start gap-2 bg-gray-dark100 p-4"
            >
              <h2
                className="font-medium"
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.15rem',
                }}
              >
                {faq.title}
              </h2>
              <p
                className="text-sm"
                style={{
                  lineHeight: '.95rem',
                }}
              >
                {addSpaceBetweenParagraphs(faq.description)}
              </p>
            </div>
          ))}
        </div>
      </section>
      <footer className="flex flex-col w-full gap-4 absolute bottom-4 p-8">
        <h1
          className="font-bold w-full text-right"
          style={{
            fontSize: '1.75rem',
            lineHeight: '1.75rem',
          }}
        >
          Não encontrou o<br />
          que precisava?
        </h1>
        <div className="flex w-full items-center justify-between">
          <NexLogo
            style={{
              width: '6rem',
              height: '3rem',
            }}
          />
          <p
            className="text-xl font-normal text-right"
            style={{
              lineHeight: '1.25rem',
            }}
          >
            O nosso time de Sucesso do Cliente está disponível para <br /> tirar
            demais dúvidas via o Whatsapp (41) 9 9931 - 1777
          </p>
        </div>
      </footer>
    </div>
  );
}
