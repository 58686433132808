import Table from 'ui/components/table/Table';
import TableBody from 'ui/components/table/TableBody';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';

import GenerationUnitLeaseCyclesTableItem from './GenerationUnitLeaseCyclesTableItem';

import { GenerationUnitLeaseCycle } from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

type Props = {
  onClickCreateButton: (
    pathname: string,
    generationUnitLeaseCycle: GenerationUnitLeaseCycle
  ) => void;
  onClickOpenBillDetails: (
    generationUnitLeaseCycle: GenerationUnitLeaseCycle
  ) => void;
  generationUnitLeaseCycles: GenerationUnitLeaseCycle[];
};

export default function GenerationUnitLeaseCyclesTable({
  onClickCreateButton,
  onClickOpenBillDetails,
  generationUnitLeaseCycles,
}: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>UNIDADE GERADORA</TableHeadColumn>
        <TableHeadColumn>STATUS DO CICLO</TableHeadColumn>
        <TableHeadColumn>CICLO DE LOCAÇÃO</TableHeadColumn>
        <TableHeadColumn>UC'S</TableHeadColumn>
        <TableHeadColumn className="text-right">FATURA</TableHeadColumn>
      </TableHead>
      <TableBody>
        {generationUnitLeaseCycles.map(
          (generationUnitLeaseCycle: GenerationUnitLeaseCycle) => (
            <GenerationUnitLeaseCyclesTableItem
              key={generationUnitLeaseCycle.id}
              onClickCreateButton={onClickCreateButton}
              onClickOpenBillDetails={onClickOpenBillDetails}
              generationUnitLeaseCycles={generationUnitLeaseCycle}
            />
          )
        )}
      </TableBody>
    </Table>
  );
}
