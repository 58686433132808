import { useHistory } from 'react-router-dom';

import { Button } from 'ui';
import { ModalFormProps } from 'ui/models/overlay';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { ConsumerUnitBillingCycle } from 'consumerUnits/consumerUnitBillingCycles/models/consumerUnitBillingCycles';

export type ConsumerUnitBillDataModal = {
  show: boolean;
  pathname: string;
  consumerUnitBillingCycle: ConsumerUnitBillingCycle | null;
};

type Props = {
  danger: ToastProps;
  onClickContinue?: () => void;
  onClickCloseModal: () => void;
  ModalOverlay: (any: ModalFormProps) => JSX.Element;
  consumerUnitBillData?: ConsumerUnitBillDataModal;
};

export default function DangerConsumerUnitBillData({
  danger,
  ModalOverlay,
  onClickContinue,
  onClickCloseModal,
  consumerUnitBillData,
}: Props) {
  const { push } = useHistory();
  const { LoadingOverlay } = useLoading();

  const onClickCreate = () =>
    push({
      pathname: consumerUnitBillData?.pathname,
      state: consumerUnitBillData?.consumerUnitBillingCycle,
    });

  return (
    <ModalOverlay title={danger.title} variant={danger.variant}>
      <div className="grid grid-cols-12">
        <div className="col-start-1 col-end-12">
          <p className="text-gray-dark400 font-small text-sm">{danger.text}</p>
        </div>
      </div>
      <div className="flex flex-row-reverse mt-8 gap-4">
        <Button
          size="md"
          variant="primaryRed"
          onClick={(consumerUnitBillData && onClickCreate) || onClickContinue}
        >
          <p className="font-medium">Continuar</p>
        </Button>

        <Button variant="primaryGray" size="md" onClick={onClickCloseModal}>
          <p className="font-medium">Cancelar</p>
        </Button>
      </div>
      <LoadingOverlay />
    </ModalOverlay>
  );
}
