import { useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { Tooltip } from 'ui/components/tooltip';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

import ErrorIcon from 'assets/icons/list/ErrorIcon';
import PendingIcon from 'assets/icons/list/PendingIcon';
import SuccessIcon from 'assets/icons/list/SuccessIcon';
import UnavailableIcon from 'assets/icons/list/UnavailableIcon';

import { formatDateToLocaleDateString } from 'utils/form';

import {
  GenerationUnitLeaseCycle,
  GenerationUnitLeaseCycleStatus,
} from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

import { ConsumerUnitBillingCaptureStatus } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

type Props = {
  onClickCreateButton: (
    pathname: string,
    generationUnitLeaseCycle: GenerationUnitLeaseCycle
  ) => void;
  onClickOpenBillDetails: (
    generationUnitLeaseCycle: GenerationUnitLeaseCycle
  ) => void;
  generationUnitLeaseCycles: GenerationUnitLeaseCycle;
};

const DATE_CONFIG_OPTIONS = {
  options: { month: '2-digit', year: 'numeric' } as Intl.DateTimeFormatOptions,
};

const CREATE_ROUTE = '/dashboard/bill-data/generation-units/create';
const CREDIT_DISTRIBUTION_ROUTE =
  '/dashboard/billing-cycle/apportionment-cycle/credit-distribution';

export default function GenerationUnitLeaseCyclesTableItem({
  onClickCreateButton,
  onClickOpenBillDetails,
  generationUnitLeaseCycles: {
    id,
    generationUnit,
    consumerUnitBillingCapture,
    generationUnitLeaseCycleDate,
    powerDistributionUnitBillDatas,
    generationUnitLeaseCycleStatus,
    generationUnitLeaseCycleAlreadyFinished,
  },
}: Props) {
  const { push } = useHistory();

  const {
    consumerUnitBillingCaptureStatus = ConsumerUnitBillingCaptureStatus.WAITING,
    consumerUnitBillingCaptureError,
  } = consumerUnitBillingCapture || {};

  const GENERATION_UNIT_LEASE_CYCLE: GenerationUnitLeaseCycle = {
    id,
    generationUnit,
    consumerUnitBillingCapture,
    generationUnitLeaseCycleDate,
    powerDistributionUnitBillDatas,
    generationUnitLeaseCycleStatus,
    generationUnitLeaseCycleAlreadyFinished,
  };

  const HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED =
    !!powerDistributionUnitBillDatas &&
    consumerUnitBillingCaptureStatus === ConsumerUnitBillingCaptureStatus.SUCCESS;

  const translateGenerationUnitLeaseCycleStatusToPortuguese = {
    [GenerationUnitLeaseCycleStatus.OPENED]: 'EM ABERTO',
    [GenerationUnitLeaseCycleStatus.PENDING]: 'PENDENTE',
    [GenerationUnitLeaseCycleStatus.BILLING]:
      (HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED &&
        generationUnitLeaseCycleAlreadyFinished &&
        'FINALIZADO') ||
      'FATURANDO',
  };

  const consumerUnitBillingCaptureStatusErrorActived =
    consumerUnitBillingCaptureStatus === ConsumerUnitBillingCaptureStatus.ERROR;

  const consumerUnitBillingCaptureStatusWaitingActived =
    consumerUnitBillingCaptureStatus === ConsumerUnitBillingCaptureStatus.WAITING;

  const labelStatusIcon = useMemo(() => {
    const status = {
      [ConsumerUnitBillingCaptureStatus.ERROR]: `Erro: ${
        consumerUnitBillingCaptureError ||
        'Falha na captura da fatura, reporte ao time de Produto'
      }`,
      [ConsumerUnitBillingCaptureStatus.SUCCESS]:
        (HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED && 'Detalhes da fatura') ||
        'Pendente de informação',
      [ConsumerUnitBillingCaptureStatus.WAITING]: 'Fatura indisponível',
    };

    return status[consumerUnitBillingCaptureStatus];
  }, [
    consumerUnitBillingCaptureError,
    consumerUnitBillingCaptureStatus,
    HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED,
  ]);

  return (
    <TableBodyRow dataTestId={`generation-unit-lease-cycles-row-id-${id}`}>
      <TableBodyColumn>
        <p className="font-bold">{generationUnit.generationUnitLegalName}</p>
      </TableBodyColumn>
      <TableBodyColumn>
        <p className="font-bold">
          {
            translateGenerationUnitLeaseCycleStatusToPortuguese[
              generationUnitLeaseCycleStatus
            ]
          }
        </p>
      </TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(generationUnitLeaseCycleDate, {
          year: DATE_CONFIG_OPTIONS.options.year,
          month: DATE_CONFIG_OPTIONS.options.month,
        })}
      </TableBodyColumn>
      <TableBodyColumn>
        <Tooltip label={'Distribuição de créditos'} placement="bottom">
          <p
            className="underline"
            onClick={() => {
              push({
                pathname: CREDIT_DISTRIBUTION_ROUTE,
                state: {
                  generationUnitId: generationUnit.id,
                  apportionmentCycleConsumerUnitReferenceMonth:
                    powerDistributionUnitBillDatas?.billReferenceDate,
                },
              });
            }}
          >
            {0 /*CONSUMER UNIT COUNT QUERY - WAITING FOR THE API */}
          </p>
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <Tooltip label={labelStatusIcon} placement="bottom">
          {consumerUnitBillingCaptureStatusErrorActived && (
            <ErrorIcon
              OnClick={() =>
                onClickCreateButton(CREATE_ROUTE, GENERATION_UNIT_LEASE_CYCLE)
              }
            />
          )}
          {consumerUnitBillingCaptureStatusWaitingActived && (
            <UnavailableIcon
              OnClick={() =>
                onClickCreateButton(CREATE_ROUTE, GENERATION_UNIT_LEASE_CYCLE)
              }
            />
          )}
          {(HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED && (
            <SuccessIcon
              OnClick={() =>
                onClickOpenBillDetails({
                  id,
                  generationUnit,
                  consumerUnitBillingCapture,
                  generationUnitLeaseCycleDate,
                  powerDistributionUnitBillDatas,
                  generationUnitLeaseCycleStatus,
                  generationUnitLeaseCycleAlreadyFinished,
                })
              }
            />
          )) ||
            (!consumerUnitBillingCaptureStatusWaitingActived &&
              !consumerUnitBillingCaptureStatusErrorActived && (
                <PendingIcon
                  OnClick={() =>
                    onClickCreateButton(CREATE_ROUTE, GENERATION_UNIT_LEASE_CYCLE)
                  }
                />
              ))}
        </Tooltip>
      </TableBodyColumn>
    </TableBodyRow>
  );
}
