import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import has from 'lodash/has';
import omit from 'lodash/omit';
import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { formatDateToSubmitDateString, setFormError } from 'utils/form';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import PisCofinsTaxFormFields, {
  FormFields,
} from 'pisCofinsTaxes/components/form/PisCofinsTaxFields';
import {
  PowerDistributionUnitPisCofinsTaxCreated,
  PowerDistributionUnitPisCofinsTaxCreateMutationVariables,
  POWER_DISTRIBUTION_UNIT_PIS_COFINS_TAX_CREATE_MUTATION,
} from 'pisCofinsTaxes/graphql/pisCofinsTaxCreateMutation';
import {
  PisCofinsTaxesList,
  pisCofinsTaxesTypename,
  PIS_COFINS_TAXES_QUERY,
} from 'pisCofinsTaxes/graphql/pisCofinsTaxesQuery';
import {
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES,
  PowerDistributionUnitsSelectList,
} from 'powerDistributionUnits/graphql/powerDistributionUnitsSelectQuery';

const DASHBOARD_PIS_COFINS_TAXES_ROUTE = '/dashboard/taxes-fees/pis-cofins-taxes';

const BREADCRUMB_PAGES: Page[] = [
  { name: 'PIS/COFINS', route: DASHBOARD_PIS_COFINS_TAXES_ROUTE, current: false },
  {
    name: 'Novo PIS/COFINS',
    route: null,
    current: true,
  },
];
const TITLE = 'Novo PIS/COFINS';
const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar a PIS/COFINS',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao cadastrar o PIS/COFINS.',
};

export default function CreatePisCofinsTaxesPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [pisCofinsTaxCreateMutation, { loading: loadingCreate }] = useMutation<
    PowerDistributionUnitPisCofinsTaxCreated,
    PowerDistributionUnitPisCofinsTaxCreateMutationVariables
  >(POWER_DISTRIBUTION_UNIT_PIS_COFINS_TAX_CREATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(CREATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(CREATE_SUCCESS_TOAST);
      push(DASHBOARD_PIS_COFINS_TAXES_ROUTE);
    },
  });

  const [
    powerDistributionUnitsSelect,
    { data: powerDistributionUnits, loading, refetch },
  ] = useLazyQuery<PowerDistributionUnitsSelectList>(
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES
  );

  const onSubmit: SubmitHandler<FormFields> = (
    powerDistributionUnitPisCofinsTaxCreateInput
  ) => {
    const [validityStart, validityEnd] =
      powerDistributionUnitPisCofinsTaxCreateInput.pisCofinsValidity;

    const pisCofinsValidityStartAt = formatDateToSubmitDateString(
      String(validityStart)
    );
    const pisCofinsValidityEndAt = formatDateToSubmitDateString(String(validityEnd));

    pisCofinsTaxCreateMutation({
      variables: {
        powerDistributionUnitPisCofinsTaxCreateInput: {
          ...omit(powerDistributionUnitPisCofinsTaxCreateInput, 'pisCofinsValidity'),
          pisCofinsValidityStartAt,
          pisCofinsValidityEndAt,
        },
      },
      update(cache, { data }) {
        const existingData = cache.readQuery<PisCofinsTaxesList>({
          query: PIS_COFINS_TAXES_QUERY,
        });
        cache.writeQuery({
          query: PIS_COFINS_TAXES_QUERY,
          data: {
            powerDistributionUnitPisCofinsTaxes: {
              __typename: pisCofinsTaxesTypename,
              afterCursor:
                existingData?.powerDistributionUnitPisCofinsTaxes.afterCursor ||
                null,
              beforeCursor:
                existingData?.powerDistributionUnitPisCofinsTaxes.beforeCursor ||
                null,
              entries: [
                data?.powerDistributionUnitPisCofinsTaxCreate,
                ...(existingData?.powerDistributionUnitPisCofinsTaxes.entries || []),
              ],
            },
          },
        });
      },
    });
  };

  const isLoading = loading || loadingCreate;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  useEffect(() => {
    refetch && refetch();
    powerDistributionUnitsSelect();
  }, [powerDistributionUnitsSelect, refetch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            <Button type="submit" size="sm" disabled={loading}>
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <PisCofinsTaxFormFields
          powerDistributionUnits={
            powerDistributionUnits?.powerDistributionUnits.entries
          }
          errors={errors}
          register={register}
          control={control}
        />
      </Dashboard>
    </form>
  );
}
