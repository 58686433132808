import { EconomyReport as EconomyReportProps } from 'economyReport/model/economyReport';

import FirstPageEconomyReport from 'economyReport/components/FirstPageEconomyReport';
import SecondPageEconomyReport from 'economyReport/components/SecondPageEconomyReport';

export type Props = {
  economyReport: EconomyReportProps;
};

export default function EconomyReport({ economyReport }: Props) {
  return (
    <div className="flex flex-col w-full items-center justify-start bg-gray-dark600 py-6 gap-6">
      <FirstPageEconomyReport economyReport={economyReport} />
      <SecondPageEconomyReport economyReport={economyReport} />
    </div>
  );
}
