import { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import NexLogo from 'assets/logo/NexLogo';

import { MdHelp, MdReportProblem } from 'react-icons/md';

import { EconomyReport } from 'economyReport/model/economyReport';

import { ConsumptionGroupsType } from 'consumerUnits/model/consumerUnit';

import { EconomyReport as EconomyReportData } from '__factory__/economyReport';

import { getPartsFromDate } from 'utils/form';

import { TariffFlagModalityModalityFlagType } from 'tariffFlagTariffs/models/tariffFlagModality';
import EconomyReportGroupB from 'assets/background/economy-report/EconomyReportGroupB';
import EconomyReportGroupA from 'assets/background/economy-report/EconomyReportGroupA';

export type Props = {
  economyReport: EconomyReport;
};

type MonthAndYearProps = {
  date: string;
  spliter: '/' | '-';
  options: Intl.DateTimeFormatOptions;
};

export default function FirstPageEconomyReport({
  economyReport = EconomyReportData(1),
}: Props) {
  const {
    consumerUnit,
    generationUnit,
    powerDistributionUnitBillData,
    tariffFlagModality,
  } = economyReport;

  const HAS_CONSUMPTION_GROUP_TYPE_B = useMemo(
    () => consumerUnit?.consumerUnitConsumptionGroupType === ConsumptionGroupsType.B,
    [consumerUnit?.consumerUnitConsumptionGroupType]
  );

  const getMonthAndYear = useCallback(
    ({ date, spliter, options }: MonthAndYearProps) =>
      `${getPartsFromDate(date, options)
        .toUpperCase()
        .replace('.', '')}${spliter}${getPartsFromDate(date, {
        year: 'numeric',
        timeZone: 'UTC',
      })}`,
    []
  );

  const getTariffFlagModalityFlag = useCallback(
    () =>
      [
        {
          key: TariffFlagModalityModalityFlagType.GREEN,
          flag: 'Bandeira Verde',
          color: 'border-primary',
        },
        {
          key: TariffFlagModalityModalityFlagType.YELLOW,
          flag: 'Bandeira Amarela',
          color: 'border-yellow-500',
        },
        {
          key: TariffFlagModalityModalityFlagType.RED_ON_PEAK,
          flag: 'Bandeira Vermelha PT1',
          color: 'border-red-a1',
        },
        {
          key: TariffFlagModalityModalityFlagType.RED_ON_PEAK_TWO,
          flag: 'Bandeira Vermelha PT2',
          color: 'border-red-a1',
        },
      ].find(
        ({ key }) => key === tariffFlagModality?.tariffFlagModalityModalityFlag
      ),
    [tariffFlagModality?.tariffFlagModalityModalityFlag]
  );

  const HAS_CONSUMER_UNIT_INFO = useMemo(
    () => [
      {
        key: 'UNIDADE CONSUMIDORA',
        value: consumerUnit?.consumerUnitPowerDistributionUnitIdentifier,
      },
      {
        key: 'CNPJ/CPF',
        value: consumerUnit?.cooperativeMember.cooperativeMemberDocumentId,
      },
      {
        key: 'ENDEREÇO',
        value:
          (consumerUnit &&
            `${consumerUnit?.consumerUnitAddressCity}, 
          ${
            !!consumerUnit.consumerUnitAddressComplement
              ? consumerUnit?.consumerUnitAddressDistrict + ' -'
              : consumerUnit?.consumerUnitAddressDistrict + ' /'
          }
          ${
            !!consumerUnit.consumerUnitAddressComplement
              ? consumerUnit?.consumerUnitAddressComplement + ' /'
              : ''
          }
          ${consumerUnit?.consumerUnitAddressState} - 
          ${consumerUnit?.consumerUnitAddressPostalCode}`) ||
          '',
      },
    ],
    [consumerUnit]
  );

  const HAS_GENERATION_UNIT_INFO = useMemo(
    () => [
      {
        key: 'POTÊNCIA INSTALADA',
        value: generationUnit?.generationUnitCapacityFactorAverage + 'MW', //REFACTOR
      },
      {
        key: 'PERCENTUAL ALUGADO',
        value: generationUnit?.generationUnitPowerCapacity + '%', //REFACTOR
      },
      {
        key: 'ENDEREÇO',
        value:
          (generationUnit &&
            `${generationUnit?.generationUnitAddressCity}, 
          ${
            !!generationUnit.generationUnitAddressComplement
              ? generationUnit?.generationUnitAddressDistrict + ' -'
              : generationUnit?.generationUnitAddressDistrict + ' /'
          }
          ${
            !!generationUnit.generationUnitAddressComplement
              ? generationUnit?.generationUnitAddressComplement + ' /'
              : ''
          }
          ${generationUnit?.generationUnitAddressState} - 
          ${generationUnit?.generationUnitAddressPostalCode}`) ||
          '',
      },
    ],
    [generationUnit]
  );

  const HAS_BILLING_INFO = useMemo(
    () => [
      {
        key: 'IDENTIFICADOR DA FATURA',
        value: `
          RE-${consumerUnit?.consumerUnitPowerDistributionUnitIdentifier}
          -${getMonthAndYear({
            spliter: '-',
            options: {
              month: '2-digit',
              timeZone: 'UTC',
            },
            date: powerDistributionUnitBillData?.billReferenceDate,
          })}`,
      },
      {
        key: 'MÊS DE REFERÊNCIA',
        value: getMonthAndYear({
          spliter: '/',
          options: {
            month: 'short',
            timeZone: 'UTC',
          },
          date: powerDistributionUnitBillData?.billReferenceDate,
        }),
      },
      {
        key: 'BANDEIRA TARIFÁRIA',
        value: getTariffFlagModalityFlag()?.flag,
      },
    ],
    [
      getMonthAndYear,
      getTariffFlagModalityFlag,
      powerDistributionUnitBillData?.billReferenceDate,
      consumerUnit?.consumerUnitPowerDistributionUnitIdentifier,
    ]
  );

  const HAS_WALLET_INFO = useMemo(
    () => [
      {
        key: '2022-01-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
      {
        key: '2022-02-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
      {
        key: '2022-03-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
      {
        key: '2022-04-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
      {
        key: '2022-05-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
      {
        key: '2022-06-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
      {
        key: '2022-07-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
      {
        key: '2022-08-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
      {
        key: '2022-09-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
      {
        key: '2022-10-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
      {
        key: '2022-11-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
      {
        key: '2022-12-01',
        value: `R$ ${powerDistributionUnitBillData?.billTotalValue}`,
      },
    ],
    [powerDistributionUnitBillData?.billTotalValue]
  );

  return (
    <div
      id="economy-report-first-page"
      className="flex flex-col w-full items-center justify-start h-screen bg-white p-7 gap-1 font-rubik relative"
      style={{
        width: '210mm',
        height: '297mm',
      }}
    >
      <header className="w-full flex items-center justify-between pb-6">
        <h1
          className="font-bold font-rubik"
          style={{
            fontSize: '2rem',
            lineHeight: '2rem',
          }}
        >
          RELATÓRIO DE ECONOMIA
        </h1>
        <NexLogo />
      </header>
      <section className="flex w-full items-start justify-between pl-6 gap-6">
        <div className="flex flex-col items-start justify-start gap-6">
          <div className="flex items-center justify-start">
            <h1
              className="font-bold max-w-xs"
              style={{
                fontSize: '1.75rem',
                lineHeight: '2rem',
              }}
            >
              {consumerUnit?.cooperativeMember.cooperativeMemberLegalName}
            </h1>
          </div>
          <div className="flex flex-col items-start justify-center gap-1">
            {HAS_CONSUMER_UNIT_INFO.map((item) => (
              <p
                key={item.key}
                className="font-light text-sm"
                style={{
                  letterSpacing: '.05rem',
                }}
              >
                {item.key}:{' '}
                <span
                  className="font-bold"
                  style={{
                    letterSpacing: 'normal',
                  }}
                >
                  {item.value || '-'}
                </span>
              </p>
            ))}
          </div>
        </div>
        <div
          className={classNames('flex flex-col bg-gray-dark200 gap-4', {
            [getTariffFlagModalityFlag()?.color as string]:
              !!getTariffFlagModalityFlag(),
          })}
          style={{
            borderLeftWidth: '3px',
            padding: '1.5rem 2rem',
          }}
        >
          {HAS_BILLING_INFO.map((item) => (
            <div key={item.key}>
              <p
                className="font-light text-xs"
                style={{
                  lineHeight: '.5rem',
                  letterSpacing: '.05rem',
                }}
              >
                {item.key}:{' '}
              </p>
              <span
                className="font-bold text-sm"
                style={{
                  letterSpacing: 'normal',
                }}
              >
                {item.value || '-'}
              </span>
            </div>
          ))}
        </div>
      </section>
      <section
        className="flex flex-col w-full items-start justify-start"
        style={{
          padding: '1rem 0',
        }}
      >
        <div
          className="flex w-full bg-gray-dark200 relative"
          style={{
            padding: '.5rem 1rem .5rem 1rem',
          }}
        >
          <div
            className="flex flex-col"
            style={{
              width: HAS_CONSUMPTION_GROUP_TYPE_B ? '550px' : 'inherit',
            }}
          >
            <div
              className="flex flex-col gap-1"
              style={{
                padding: '1rem 1rem .5rem 1rem',
              }}
            >
              <div
                className={classNames(
                  'flex items-center justify-between border-b-2 border-gray gap-4',
                  {
                    'grid grid-cols-3': !HAS_CONSUMPTION_GROUP_TYPE_B,
                  }
                )}
              >
                <h2
                  className="leading-5 pb-1"
                  style={{
                    width: 'max-content',
                  }}
                >
                  CARTEIRA DE <br /> CRÉDITOS
                </h2>
                {!HAS_CONSUMPTION_GROUP_TYPE_B && (
                  <>
                    <h3 className="font-semibold text-center text-sm leading-4">
                      PONTA
                    </h3>
                    <h3 className="font-semibold text-center text-sm leading-4">
                      FORA <br />
                      PONTA
                    </h3>
                  </>
                )}
              </div>
              <div
                className={classNames(
                  'flex items-center justify-between gap-4 pt-1',
                  {
                    'grid grid-cols-3': !HAS_CONSUMPTION_GROUP_TYPE_B,
                  }
                )}
              >
                <h3
                  className="font-semibold leading-4"
                  style={{
                    fontSize: '0.8rem',
                  }}
                >
                  CRÉDITOS <br /> ACUMULADOS¹
                </h3>
                <p className="font-normal text-2xl text-center">
                  {
                    powerDistributionUnitBillData?.billExcessEnergyCredits
                      ?.excessEnergyCreditsOnPeak
                  }
                </p>
                {!HAS_CONSUMPTION_GROUP_TYPE_B && (
                  <p className="font-normal text-2xl text-center">
                    {
                      powerDistributionUnitBillData?.billExcessEnergyCredits
                        ?.excessEnergyCreditsOffPeak
                    }
                  </p>
                )}
              </div>
              <div
                className={classNames(
                  'flex items-center justify-between border-b-2 border-t-2 border-gray pt-2 pb-2 gap-4',
                  {
                    'grid grid-cols-3': !HAS_CONSUMPTION_GROUP_TYPE_B,
                  }
                )}
              >
                <h3
                  className="font-semibold leading-4"
                  style={{
                    fontSize: '0.8rem',
                  }}
                >
                  CRÉDITOS <br /> RECEBIDOS²
                </h3>
                <p className="font-normal text-2xl text-center">
                  {
                    powerDistributionUnitBillData?.billGeneratedEnergyCredits
                      ?.generatedEnergyCreditsOnPeak
                  }
                </p>
                {!HAS_CONSUMPTION_GROUP_TYPE_B && (
                  <p className="font-normal text-2xl text-center">
                    {
                      powerDistributionUnitBillData?.billGeneratedEnergyCredits
                        ?.generatedEnergyCreditsOffPeak
                    }
                  </p>
                )}
              </div>
              <div
                className={classNames(
                  'flex items-center justify-between gap-4 pt-1',
                  {
                    'grid grid-cols-3': !HAS_CONSUMPTION_GROUP_TYPE_B,
                  }
                )}
              >
                <h3
                  className="font-semibold leading-4"
                  style={{
                    fontSize: '0.8rem',
                  }}
                >
                  CRÉDITOS <br /> UTILIZADOS²
                </h3>
                <p className="font-normal text-2xl text-center">
                  {
                    powerDistributionUnitBillData?.billConsumedEnergyCredits
                      ?.consumedEnergyCreditsOnPeak
                  }
                </p>
                {!HAS_CONSUMPTION_GROUP_TYPE_B && (
                  <p className="font-normal text-2xl text-center">
                    {
                      powerDistributionUnitBillData?.billConsumedEnergyCredits
                        ?.consumedEnergyCreditsOffPeak
                    }
                  </p>
                )}
              </div>
            </div>
            <div
              className={classNames(
                'flex items-center justify-between bg-primary gap-4',
                {
                  'grid grid-cols-3': !HAS_CONSUMPTION_GROUP_TYPE_B,
                }
              )}
              style={{
                padding: '.75rem 1rem',
              }}
            >
              <h3
                className="font-semibold leading-4"
                style={{
                  fontSize: '0.8rem',
                }}
              >
                CRÉDITOS <br /> RESTANTES²
              </h3>
              <p className="font-semibold text-2xl text-center">
                {+powerDistributionUnitBillData?.billGeneratedEnergyCredits
                  ?.generatedEnergyCreditsOnPeak -
                  +powerDistributionUnitBillData?.billConsumedEnergyCredits
                    ?.consumedEnergyCreditsOnPeak}
              </p>
              {!HAS_CONSUMPTION_GROUP_TYPE_B && (
                <p className="font-semibold text-2xl text-center">
                  {+powerDistributionUnitBillData?.billGeneratedEnergyCredits
                    ?.generatedEnergyCreditsOffPeak -
                    +powerDistributionUnitBillData?.billConsumedEnergyCredits
                      ?.consumedEnergyCreditsOffPeak}
                </p>
              )}
            </div>
            <div
              className="flex"
              style={{
                padding: '.5rem 1rem .25rem 1rem',
              }}
            >
              <span className="text-xs leading-4">
                ¹ Saldo de créditos referente à períodos anteriores. <br />² Créditos
                referentes ao ciclo de faturamento atual.
              </span>
            </div>
          </div>
          <div className="flex flex-col w-full p-4 gap-2">
            <h1 className="font-bold text-2xl max-w-xs pb-2 leading-6">
              {generationUnit.generationUnitLegalName}
            </h1>
            {HAS_GENERATION_UNIT_INFO.map((item) => (
              <div key={item.key}>
                <h3
                  className="font-semibold leading-4"
                  style={{
                    fontSize: '0.8rem',
                  }}
                >
                  {item.key}
                </h3>
                <p className="text-xs">{item.value}</p>
              </div>
            ))}
          </div>
          {HAS_CONSUMPTION_GROUP_TYPE_B ? (
            <EconomyReportGroupB className="absolute bottom-0 right-0" />
          ) : (
            <EconomyReportGroupA className="absolute bottom-0 right-0" />
          )}
        </div>
        <div
          className="flex gap-6 items-center justify-start bg-gray"
          style={{
            padding: '1rem 1.5rem',
          }}
        >
          <h1
            className="flex items-center justify-start gap-1 font-bold text-lg"
            style={{
              lineHeight: '1rem',
            }}
          >
            <MdReportProblem size={18} /> AVISOS
          </h1>
          <p
            style={{
              fontSize: '0.65rem',
              lineHeight: '.75rem',
            }}
          >
            Conforme previsão de chuva para esse mês, a usina produziu mais créditos
            do que o seu consumo. Conforme previsão de chuva para esse mês, a usina
            produziu mais créditos do que o seu consumo.
            {/*REFACTOR*/}
          </p>
        </div>
      </section>
      <section className="flex w-full items-start justify-between">
        <div className="flex flex-col items-start justify-start gap-4">
          <div className="flex flex-col items-start justify-start w-full gap-4">
            <h1
              className="font-bold text-2xl leading-6"
              style={{
                padding: '0 1rem',
              }}
            >
              Entenda a sua fatura com a NEX
            </h1>
            <div
              className="flex items-center w-full justify-between"
              style={{
                padding: '0 1rem',
              }}
            >
              <h3
                className="font-semibold leading-4"
                style={{
                  fontSize: '0.8rem',
                }}
              >
                FATURA ATUAL DA <br /> CONCESSIONÁRIA
              </h3>
              <h2 className="text-2xl">R$ 3.250,45 {/*REFACTOR*/}</h2>
            </div>
            <div
              className="flex items-center w-full justify-between border-primary"
              style={{
                borderWidth: '3px',
                padding: '.5rem 1rem',
              }}
            >
              <div className="flex flex-col items-start justify-start">
                <h3
                  className="font-semibold leading-4"
                  style={{
                    fontSize: '0.8rem',
                  }}
                >
                  FATURA DA NEX ENERGY
                </h3>
                <p className="text-xs">
                  Vencimento -{' '}
                  {getMonthAndYear({
                    spliter: '/',
                    options: {
                      timeZone: 'UTC',
                      month: '2-digit',
                    },
                    date: powerDistributionUnitBillData?.billDueDate,
                  })}
                </p>
              </div>
              <h2 className="text-2xl">R$ 8.820,30 {/*REFACTOR*/}</h2>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-4">
            <h1
              className="font-bold text-2xl leading-6"
              style={{
                padding: '0 1rem',
              }}
            >
              Sua economia esse mês
            </h1>
            <div
              className="flex items-center w-full justify-between"
              style={{
                padding: '0 1rem',
              }}
            >
              <h3
                className="font-semibold leading-4"
                style={{
                  fontSize: '0.8rem',
                }}
              >
                TOTAL CONCESSIONÁRIA <br /> + NEX ENERGY
              </h3>
              <h2 className="text-2xl">R$ 12.070,75 {/*REFACTOR*/}</h2>
            </div>
            <div
              className="flex items-center w-full justify-between bg-primary"
              style={{
                padding: '.75rem 1rem',
              }}
            >
              <h3
                className="font-semibold leading-4"
                style={{
                  fontSize: '0.8rem',
                }}
              >
                ECONOMIA <br /> DESSE MÊS
              </h3>
              <h2 className="font-semibold text-2xl">R$ 1.485,15 {/*REFACTOR*/}</h2>
            </div>
            <div
              className="flex gap-6 items-center justify-start bg-gray relative mt-2"
              style={{
                padding: '.75rem 1.25rem',
              }}
            >
              <div
                className="absolute bg-gray w-5 h-5 rotate-45"
                style={{
                  top: '-.5rem',
                  right: '13.5rem',
                }}
              />
              <div className="flex items-center justify-start gap-6">
                <MdHelp size={18} />
                <p
                  style={{
                    fontSize: '0.65rem',
                    lineHeight: '.75rem',
                  }}
                >
                  Economia calculada com base no valor projetado de como seria a
                  fatura do <br />
                  consumidor sem a NEX. Aproximadamente este valor seria{' '}
                  <strong>R$ 33.400,00 {/*REFACTOR*/}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex flex-col bg-primary gap-1"
          style={{
            padding: '1rem 1.5rem',
          }}
        >
          <h3 className="font-bold">$ CARTEIRA DE ECONOMIA</h3>
          {/* REFACTOR: This is a temporary solution */}
          {HAS_WALLET_INFO.map((item, index) => (
            <div
              key={index}
              className={classNames(
                'flex w-full items-center justify-between pb-1 border-b border-gray-dark550',
                {
                  'border-none': index === HAS_WALLET_INFO.length - 1,
                }
              )}
            >
              <p className="text-xs">
                {getMonthAndYear({
                  date: item.key,
                  options: {
                    month: 'short',
                    timeZone: 'UTC',
                  },
                  spliter: '/',
                }).toUpperCase()}
              </p>
              <p className="text-xs">{item.value}</p>
            </div>
          ))}
          <div
            className="flex flex-col items-start justify-center bg-white"
            style={{
              padding: '.5rem .75rem',
            }}
          >
            <p className="text-xs">TOTAL ECONOMIZADO</p>
            <h1 className="font-semibold text-2xl leading-5">
              R$ 24.673,00 {/*REFACTOR*/}
            </h1>
          </div>
        </div>
      </section>
      <footer className="w-56 h-2 bg-gray-dark200 bottom-6 left-6 absolute" />
    </div>
  );
}
