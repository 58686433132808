import { gql } from '@apollo/client';

export const economyReportTypename = 'EconomyReport';

//REFACTOR: This is a temporary solution to get the economy report working

export const ECONOMY_REPORT_QUERY = gql`
  query economyReport($id: ID!) {
    economyReport
  }
`;
