import { Button } from 'ui';

import { MdClose } from 'react-icons/md';

import { ConsumerUnitBillingCycle } from 'consumerUnits/consumerUnitBillingCycles/models/consumerUnitBillingCycles';

import { convertToDecimal, formatDateToLocaleDateString } from 'utils/form';
import { BillConsumedEnergyCreditsTariffs } from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/models/consumerUnitBillData';

export type Props = {
  onClickCloseModal?: () => void;
  consumerUnitBillingCycle?: ConsumerUnitBillingCycle;
};

const DATE_CONFIG_OPTIONS = {
  options: { month: '2-digit', year: 'numeric' } as Intl.DateTimeFormatOptions,
};

export default function ConsumerUnitBillDataCreditsWallet({
  onClickCloseModal,
  consumerUnitBillingCycle,
}: Props) {
  const {
    consumerUnitBillingCycleDate,
    consumerUnit: { consumerUnitPowerDistributionUnitIdentifier = '' } = {},
    powerDistributionUnitBillData: {
      billExcessEnergyCredits: { excessEnergyCreditsTotal = '0' } = {},
      billConsumedEnergyCredits: { consumedEnergyCreditsTotal = '0' } = {},
      billGeneratedEnergyCredits: { generatedEnergyCreditsTotal = '0' } = {},
      billConsumedEnergyCreditsTariffs = {} as BillConsumedEnergyCreditsTariffs,
    } = {},
  } = consumerUnitBillingCycle || {};

  return (
    <div className="flex flex-col items-start justify-start gap-2 h-screen fixed bg-gray-dark700 right-0 top-0 border-l border-gray-dark600">
      <div className="flex px-10 py-8 items-center justify-start gap-12 border-b border-gray-dark600">
        <h1 className="text-gray-dark400 w-full font-normal text-2xl">
          Carteira de créditos
        </h1>
        <Button
          type="button"
          size="none"
          variant="tertiaryGray"
          style={{
            padding: '.1rem',
          }}
        >
          <MdClose size={20} color="#6B6B6B" onClick={onClickCloseModal} />
        </Button>
      </div>
      <div className="w-full flex flex-col px-10 py-8 gap-2">
        <div className="flex items-center justify-start gap-x-2">
          <h2 className="text-gray-dark400 leading-4">UC:</h2>
          <span className="text-gray-dark400 font-bold">
            {consumerUnitPowerDistributionUnitIdentifier}
          </span>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">
            Mês de referência da fatura:
          </h2>
          <p className="text-gray-dark400 font-bold">
            {formatDateToLocaleDateString(consumerUnitBillingCycleDate as string, {
              year: DATE_CONFIG_OPTIONS.options.year,
              month: DATE_CONFIG_OPTIONS.options.month,
            })}
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col px-10 py-8 border-t border-gray-dark600 gap-6">
        <div>
          <h2 className="text-gray-dark400 leading-4">Créditos acumulados:</h2>
          <p className="text-gray-dark400 font-bold">
            {convertToDecimal(excessEnergyCreditsTotal)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">Créditos recebidos:</h2>
          {/*REVIEW: This is a temporary solution, we need to refactor this*/}
          <p className="text-gray-dark400 font-bold">
            {convertToDecimal(generatedEnergyCreditsTotal)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">Créditos utilizados:</h2>
          <p className="text-gray-dark400 font-bold">
            {convertToDecimal(consumedEnergyCreditsTotal)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">Créditos restantes:</h2>
          {/*REVIEW: This is a temporary solution, we need to refactor this*/}
          <p className="text-gray-dark400 font-bold">
            {convertToDecimal(
              billConsumedEnergyCreditsTariffs?.consumedEnergyCreditsTariffsTotal ||
                '0'
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
