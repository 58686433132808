import {
  TariffFlagModality as TariffFlagModalityModel,
  TariffFlagModalityModalityFlagType,
} from 'tariffFlagTariffs/models/tariffFlagModality';
import TariffFlagTariff from './tariffFlagTariff';

export const TariffFlagModality = (
  id = new Date().valueOf(),
  tariffFlagTariff = TariffFlagTariff(id),
  tariffFlagModalityModalityFlag = TariffFlagModalityModalityFlagType.RED_ON_PEAK
): TariffFlagModalityModel => ({
  id,
  tariffFlagTariff,
  tariffFlagModalityModalityFlag,
  tariffFlagModalityValidityDate: new Date(),
});

export default TariffFlagModality;
