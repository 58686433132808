import { SyntheticEvent } from 'react';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import { PisCofinsTax } from 'pisCofinsTaxes/models/pisCofinsTax';

type Props = {
  pisCofinsTax: PisCofinsTax;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
  disableEditButton: boolean;
  disableRemoveButton: boolean;
};

const PIS_COFINS_VALIDITY_FORMAT_CONFIG = {
  locale: 'pt-BR',
};

const formatValidityDate = (date: Date) => {
  const dateTime = new Date(date);
  dateTime.setDate(dateTime.getDate() + 1);

  return new Intl.DateTimeFormat(PIS_COFINS_VALIDITY_FORMAT_CONFIG.locale).format(
    dateTime
  );
};

export default function PisCofinsTaxesTableItem({
  pisCofinsTax: {
    id,
    powerDistributionUnit: {
      powerDistributionUnitLegalName,
      powerDistributionUnitCountryState,
    },
    pisCofinsValidityStartAt,
    pisCofinsValidityEndAt,
    pisCofinsPisFee: pisCofinsPisTax,
    pisCofinsCofinsFee: pisCofinsCofinsTax,
  },
  onClickEditButton,
  onClickRemoveButton,
  disableEditButton,
  disableRemoveButton,
}: Props) {
  const validityStartAt = formatValidityDate(pisCofinsValidityStartAt);
  const validityEndAt = formatValidityDate(pisCofinsValidityEndAt);

  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  return (
    <TableBodyRow dataTestId={`pis-cofins-tax-row-id-${id}`}>
      <TableBodyColumn className="font-bold">
        {powerDistributionUnitLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="text-center">
        {powerDistributionUnitCountryState}
      </TableBodyColumn>
      <TableBodyColumn>{`${validityStartAt} - ${validityEndAt}`}</TableBodyColumn>
      <TableBodyColumn className="font-bold">{pisCofinsPisTax}%</TableBodyColumn>
      <TableBodyColumn className="font-bold">{pisCofinsCofinsTax}%</TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} disabled={disableEditButton} />
        <RemoveButton
          onClick={handleClickRemoveButton}
          disabled={disableRemoveButton}
        />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
