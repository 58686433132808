import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useForm, SubmitHandler, ErrorOption } from 'react-hook-form';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { has } from 'lodash';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { formatDateToSubmitDateString, setFormError } from 'utils/form';
import TariffGRoupFormFields, {
  FormFields,
} from 'tariffGroups/components/form/TariffGroupFields';
import {
  TariffGroupCreated,
  TariffGroupMutationVariables,
  TariffGroupCreateMutationInput,
  TARIFF_GROUP_CREATE_MUTATION,
} from 'tariffGroups/graphql/tariffGroupCreateMutation';
import {
  TariffGroupList,
  TariffGroupTypename,
  TARIFF_GROUPS_LIST_QUERY,
} from 'tariffGroups/graphql/tariffGroupsListQuery';
import {
  PowerDistributionUnitsSelectList,
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
  POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES,
} from 'powerDistributionUnits/graphql/powerDistributionUnitsSelectQuery';
import {
  ConsumptionCost,
  DemandCost,
  TariffGroupModalityType,
} from 'tariffGroups/models/tariffGroup';

const DASHBOARD_TARIFF_GROUPS_ROUTE = '/dashboard/taxes-fees/tariff-groups';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Grupos Tarifários',
    route: DASHBOARD_TARIFF_GROUPS_ROUTE,
    current: false,
  },
  {
    name: 'Novo Grupo Tarifário',
    route: null,
    current: true,
  },
];
const TITLE = 'Novo Grupo Tarifário';
const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar grupo tarifário',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao criar o Grupo Tarifário.',
};

export default function CreatePowerDistributionUnitsPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    resetField,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [
    powerDistributionUnitsSelect,
    {
      data: powerDistributionUnits,
      loading: loadingPowerDistributionUnits,
      refetch: refetchPowerDistributionUnits,
    },
  ] = useLazyQuery<PowerDistributionUnitsSelectList>(
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY,
    POWER_DISTRIBUTION_UNITS_SELECT_QUERY_VARIABLES
  );

  const [tariffGroupCreateMutation, { loading: loadingCreate }] = useMutation<
    TariffGroupCreated,
    TariffGroupMutationVariables
  >(TARIFF_GROUP_CREATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(CREATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(CREATE_SUCCESS_TOAST);
      push(DASHBOARD_TARIFF_GROUPS_ROUTE);
    },
  });

  const onSubmit: SubmitHandler<FormFields> = (
    powerDistributionUnitTariffGroupData
  ) => {
    const {
      demandCostAll,
      demandCostOffPeak,
      demandCostOnPeak,
      energyDistributionServiceTariff,
      energyDistributionServiceTariffOffPeak,
      energyDistributionServiceTariffOnPeak,
      energyUsageTariff,
      energyUsageTariffOffPeak,
      energyUsageTariffOnPeak,
      tariffGroupValidity: [validityStart, validityEnd],
      tariffGroupModality,
      ...otherData
    } = powerDistributionUnitTariffGroupData;

    let conditionalFields: {
      tariffGroupDemandCosts?: DemandCost;
      tariffGroupConsumptionCosts: ConsumptionCost;
    };

    if (tariffGroupModality === TariffGroupModalityType.CONVENTIONAL) {
      conditionalFields = {
        tariffGroupConsumptionCosts: {
          energyDistributionServiceTariff: energyDistributionServiceTariffOnPeak,
          energyUsageTariff: energyUsageTariffOnPeak,
        },
      };
    } else {
      conditionalFields = {
        tariffGroupConsumptionCosts: {
          energyDistributionServiceTariffOffPeak,
          energyDistributionServiceTariffOnPeak,
          energyUsageTariffOffPeak,
          energyUsageTariffOnPeak,
        },
        tariffGroupDemandCosts:
          tariffGroupModality === TariffGroupModalityType.BLUE
            ? {
                offPeak: demandCostOffPeak,
                onPeak: demandCostOnPeak,
              }
            : {
                all: demandCostAll,
              },
      };
    }

    const tariffGroupValidityStartDate = formatDateToSubmitDateString(
      String(validityStart)
    );
    const tariffGroupValidityEndDate = formatDateToSubmitDateString(
      String(validityEnd)
    );

    const powerDistributionUnitTariffGroupCreateInput: TariffGroupCreateMutationInput =
      {
        ...otherData,
        ...conditionalFields,
        tariffGroupModality,
        tariffGroupValidityStartDate,
        tariffGroupValidityEndDate,
      };

    return tariffGroupCreateMutation({
      variables: {
        powerDistributionUnitTariffGroupCreateInput,
      },
      update(cache, { data }) {
        const existingData = cache.readQuery<TariffGroupList>({
          query: TARIFF_GROUPS_LIST_QUERY,
        });
        cache.writeQuery({
          query: TARIFF_GROUPS_LIST_QUERY,
          data: {
            powerDistributionUnitTariffGroups: {
              __typename: TariffGroupTypename,
              afterCursor:
                existingData?.powerDistributionUnitTariffGroups.afterCursor || null,
              beforeCursor:
                existingData?.powerDistributionUnitTariffGroups.beforeCursor || null,
              entries: [
                data?.powerDistributionUnitTariffGroupCreate,
                ...(existingData?.powerDistributionUnitTariffGroups.entries || []),
              ],
            },
          },
        });
      },
    });
  };

  const isLoading = loadingPowerDistributionUnits || loadingCreate;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  useEffect(() => {
    refetchPowerDistributionUnits && refetchPowerDistributionUnits();

    powerDistributionUnitsSelect();
  }, [powerDistributionUnitsSelect, refetchPowerDistributionUnits]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<></>}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm breadcrumbPages={BREADCRUMB_PAGES} title={TITLE}>
            <Button type="submit" size="sm" disabled={isLoading}>
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <TariffGRoupFormFields
          powerDistributionUnits={
            powerDistributionUnits?.powerDistributionUnits.entries
          }
          errors={errors}
          register={register}
          control={control}
          setValue={setValue}
          resetField={resetField}
          clearErrors={clearErrors}
        />
      </Dashboard>
    </form>
  );
}
