import { GenerationUnit } from 'generationUnits/models/generationUnit';

import { ConsumerUnitBillingCapture } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

import { GenerationUnitBillData } from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/models/generationUnitBillData';

export type GenerationUnitLeaseCycleId = number;

export enum GenerationUnitLeaseCycleStatus {
  OPENED = 'OPENED',
  BILLING = 'BILLING',
  PENDING = 'PENDING',
}

export interface GenerationUnitLeaseCycle {
  id: GenerationUnitLeaseCycleId;
  generationUnit: GenerationUnit;
  generationUnitLeaseCycleDate: string;
  generationUnitLeaseCycleAlreadyFinished: boolean;
  consumerUnitBillingCapture?: ConsumerUnitBillingCapture;
  powerDistributionUnitBillDatas?: GenerationUnitBillData;
  generationUnitLeaseCycleStatus: GenerationUnitLeaseCycleStatus;
}
