import { useCallback, useEffect, useMemo, useState } from 'react';

import { uniq } from 'lodash';
import omit from 'lodash/omit';

import TextField from 'ui/form/TextField';
import RadioField from 'ui/form/RadioField';
import SelectField from 'ui/form/SelectField';
import { Tooltip } from 'ui/components/tooltip';
import { Option } from 'ui/components/form/SelectInput';

import {
  Control,
  useWatch,
  Controller,
  FieldError,
  UseFormRegister,
  useForm,
  ErrorOption,
} from 'react-hook-form';

import { CooperativeSelect } from 'cooperatives/models/cooperative';

import {
  CooperativeMemberEntityType,
  CooperativeMemberModalityType,
} from 'cooperatives/models/cooperativeMember';

export type Field =
  | 'cooperativeId'
  | 'cooperativeMemberEmail'
  | 'cooperativeMemberLegalName'
  | 'cooperativeMemberDocumentId'
  | 'cooperativeMemberEntityType'
  | 'cooperativeMemberModalityType'
  | 'cooperativeMemberOptionalEmail';

export const FORM_FIELDS: Field[] = [
  'cooperativeId',
  'cooperativeMemberEmail',
  'cooperativeMemberLegalName',
  'cooperativeMemberDocumentId',
  'cooperativeMemberEntityType',
  'cooperativeMemberModalityType',
  'cooperativeMemberOptionalEmail',
];

export type FormFields = {
  cooperativeId: number;
  cooperativeMemberEmail: string;
  cooperativeMemberLegalName: string;
  cooperativeMemberDocumentId: string;
  cooperativeMemberOptionalEmail?: string | null;
  cooperativeMemberEntityType: CooperativeMemberEntityType;
  cooperativeMemberModalityType: CooperativeMemberModalityType | null;
};

export type FormFieldsOptionalEmail = Omit<
  FormFields,
  'cooperativeMemberOptionalEmail'
> & {
  cooperativeMemberOptionalEmail: string[] | null;
};

export type FormErrors = {
  cooperativeId?: FieldError;
  cooperativeMemberEmail?: FieldError;
  cooperativeMemberLegalName?: FieldError;
  cooperativeMemberDocumentId?: FieldError;
  cooperativeMemberEntityType?: FieldError;
  cooperativeMemberModalityType?: FieldError;
  cooperativeMemberOptionalEmail?: FieldError;
};

export default function CooperativeFormFields({
  control,
  register,
  formErrors,
  cooperatives,
  disabledCooperative,
}: {
  formErrors: FormErrors;
  control: Control<FormFields>;
  disabledCooperative?: boolean;
  cooperatives?: CooperativeSelect[];
  register: UseFormRegister<FormFields>;
}) {
  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormFields>();

  const [showTooltip, setShowTooltip] = useState(false);

  const [
    cooperativeId,
    cooperativeMemberEmail,
    cooperativeMemberEntityType,
    cooperativeMemberModalityType,
    cooperativeMemberOptionalEmail,
  ] = useWatch({
    control,
    name: [
      'cooperativeId',
      'cooperativeMemberEmail',
      'cooperativeMemberEntityType',
      'cooperativeMemberModalityType',
      'cooperativeMemberOptionalEmail',
    ],
  });

  const REFERENCE_EMAIL_ERROR: ErrorOption = useMemo(() => {
    return {
      type: 'manual',
      message: 'E-mail com formato inválido.',
    };
  }, []);

  const LIMIT_EMAIL_ERROR: ErrorOption = useMemo(() => {
    return {
      type: 'manual',
      message: 'Limite de e-mails excedido.',
    };
  }, []);

  const regex = useMemo(() => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  }, []);

  const [cooperativeOptions, setCooperativeOptions] = useState<Option<number>[]>([]);

  useEffect(
    () =>
      setCooperativeOptions(
        cooperatives?.map((cooperative) => ({
          key: cooperative.id,
          value: cooperative.cooperativeLegalName,
        })) || []
      ),
    [cooperatives]
  );

  const cooperativeMemberModalityTypeCompanySelected =
    cooperativeMemberEntityType === CooperativeMemberEntityType.COMPANY;

  const disabledFields = useMemo(() => {
    const disabledMap = {
      cooperativeMemberModalityType: cooperativeMemberModalityTypeCompanySelected,
    };

    return uniq(
      Object.entries(disabledMap)
        .filter(([_key, value]) => value)
        .map(([key]) => key)
    );
  }, [cooperativeMemberModalityTypeCompanySelected]);

  const isDisabled = useCallback(
    (field) => disabledFields.includes(field),
    [disabledFields]
  );

  const modalityType = useMemo(() => {
    const modalityTypeOptions = [
      { key: 'BRANCH', value: 'FILIAL' },
      { key: 'HEADQUARTER', value: 'MATRIZ' },
    ];

    return cooperativeMemberModalityTypeCompanySelected ? modalityTypeOptions : [];
  }, [cooperativeMemberModalityTypeCompanySelected]);

  useMemo(() => {
    if (cooperativeMemberEmail && cooperativeMemberEmail?.length > 1) {
      !regex.test(cooperativeMemberEmail)
        ? setError(
            'cooperativeMemberEmail' as keyof FormFields,
            REFERENCE_EMAIL_ERROR
          )
        : clearErrors('cooperativeMemberEmail' as keyof FormFields);
    }

    return () => {};
  }, [cooperativeMemberEmail, regex, setError, REFERENCE_EMAIL_ERROR, clearErrors]);

  useMemo(() => {
    if (
      cooperativeMemberOptionalEmail &&
      cooperativeMemberOptionalEmail?.length > 1
    ) {
      const splitedValues = cooperativeMemberOptionalEmail.split(',');

      if (splitedValues.length > 4) {
        return setError(
          'cooperativeMemberOptionalEmail' as keyof FormFields,
          LIMIT_EMAIL_ERROR
        );
      }

      splitedValues.forEach((value) => {
        if (!regex.test(value) && value.length > 1) {
          setError(
            'cooperativeMemberOptionalEmail' as keyof FormFields,
            REFERENCE_EMAIL_ERROR
          );
        } else {
          clearErrors('cooperativeMemberOptionalEmail' as keyof FormFields);
        }
      });
    } else {
      return clearErrors('cooperativeMemberOptionalEmail' as keyof FormFields);
    }

    return () => {};
  }, [
    setError,
    clearErrors,
    regex,
    LIMIT_EMAIL_ERROR,
    REFERENCE_EMAIL_ERROR,
    cooperativeMemberOptionalEmail,
  ]);

  return (
    <div className="grid grid-cols-2 gap-8">
      <div className="col-start-1 col-end-4">
        <Controller
          name="cooperativeMemberEntityType"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field: { ref, onChange, value } }) => (
            <RadioField
              required
              {...omit(ref, 'ref', 'value')}
              error={formErrors.cooperativeMemberEntityType?.message}
              label="Entidade"
              id="cooperativeMemberEntityType"
              options={[
                {
                  label: 'Jurídica',
                  value: 'COMPANY',
                },
                {
                  label: 'Física',
                  value: 'INDIVIDUAL',
                },
              ]}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
      <div className="grid col-start-1 gap-8">
        <TextField
          required
          error={formErrors.cooperativeMemberLegalName?.message}
          label="Razão Social/Nome"
          id="cooperativeMemberLegalName"
          {...register('cooperativeMemberLegalName', {
            required: 'Campo obrigatório',
          })}
          placeholder="Ex.: Nex Energy Gestão de Energia"
        />
        <TextField
          required
          mask="document"
          error={formErrors.cooperativeMemberDocumentId?.message}
          label="Documento"
          id="cooperativeMemberDocumentId"
          {...register('cooperativeMemberDocumentId', {
            required: 'Campo obrigatório',
          })}
          placeholder="Digite o documento CPF/CNPJ"
        />
        <TextField
          required
          error={
            errors.cooperativeMemberEmail?.message ||
            formErrors.cooperativeMemberEmail?.message
          }
          label="E-mail principal para faturamento"
          id="cooperativeMemberEmail"
          {...register('cooperativeMemberEmail', {
            required: 'Campo obrigatório',
          })}
          placeholder="example@email.com"
        />
      </div>
      <div className="grid col-start-2 gap-8">
        <Controller
          name="cooperativeId"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref', 'value')}
              error={formErrors.cooperativeId?.message}
              label="Cooperativa"
              emptyOptionLabel="Selecione uma Cooperativa"
              id="cooperativeId"
              options={cooperativeOptions}
              value={cooperativeId}
              required={!disabledCooperative}
              disabled={disabledCooperative}
            />
          )}
        />
        <Controller
          control={control}
          name="cooperativeMemberModalityType"
          render={({ field }) => (
            <SelectField
              {...omit(field, 'ref', 'value')}
              label="Tipo"
              id="cooperativeMemberModalityType"
              value={cooperativeMemberModalityType}
              options={modalityType}
              error={formErrors.cooperativeMemberModalityType?.message}
              emptyOptionLabel="Selecione um tipo (Filial / Matriz)"
              disabled={!isDisabled('cooperativeMemberModalityType')}
            />
          )}
        />
        <Tooltip
          arrowPosition="top"
          opened={showTooltip}
          placement="bottom-end"
          closeTooltip={() => setShowTooltip(false)}
          label={'Informe até 4 e-mails adicionais, separados por vírgula (,).'}
          offset={{
            crossAxis: 0,
            mainAxis: 15,
          }}
        >
          <TextField
            id="cooperativeMemberOptionalEmail"
            label="Outros e-mails para faturamento (Opcional)"
            placeholder="example1@email.com,example2@email.com"
            error={
              errors.cooperativeMemberOptionalEmail?.message ||
              formErrors.cooperativeMemberOptionalEmail?.message
            }
            onFocusCapture={() => {
              setShowTooltip(true);
            }}
            {...register('cooperativeMemberOptionalEmail')}
          />
        </Tooltip>
      </div>
    </div>
  );
}
