import { EconomyReport as EconomyReportModel } from 'economyReport/model/economyReport';

import ConsumerUnit from './consumerUnit';
import GenerationUnit from './generationUnit';
import TariffFlagModality from './tariffFlagModality';
import ConsumerUnitBillData from './consumerUnitBillData';

export const EconomyReport = (id = new Date().valueOf()): EconomyReportModel => ({
  id: id,
  consumerUnit: ConsumerUnit(id),
  generationUnit: GenerationUnit(id),
  tariffFlagModality: TariffFlagModality(id),
  powerDistributionUnitBillData: ConsumerUnitBillData(id),
});

export default EconomyReport;
