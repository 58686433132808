import { TariffFlagModalityModalityFlagType } from 'tariffFlagTariffs/models/tariffFlagModality';
import { TariffFlagTariff as TariffFlagTariffModel } from 'tariffFlagTariffs/models/tariffFlagTariff';

export const TariffFlagTariff = (
  id = new Date().valueOf(),
  tariffFlagTariffModalityFlag = TariffFlagModalityModalityFlagType.GREEN
): TariffFlagTariffModel => ({
  id,
  tariffFlagTariffFee: `${id * 2}`,
  tariffFlagTariffModalityFlag,
  tariffFlagTariffValidityPeriod: new Date(),
});

export default TariffFlagTariff;
